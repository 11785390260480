@import url("https://fonts.googleapis.com/css?family=Pathway+Gothic+One|Roboto:400,700|Oswald|Saira+Extra+Condensed|Quicksand|Open+Sans+Condensed:300&display=swap&subset=cyrillic,latin-ext");
html{
    background-color: #fbfcfe;
    position: relative;
    margin: 0;
    padding: 0;
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    color: #157ac1;
    background-image: url(/assets/images/background-600.jpg);
    background-size: 80%;
    background-position: top 33.5vw right -7.5vw;
    background-repeat: no-repeat;
}
/*@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 50vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}*/
a{text-decoration: none; }
a:hover{text-decoration: none; }
body{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body > header{
    margin: 0;
    padding: 0;
    width: 100vw;
    position: relative;
    z-index: 100;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.075);
}
body > header a{
    color: #157ac1;
}
body > header a.a-h1.cyr h1, body > header a.page-h1.cyr span{
    font-size: 10.5vw;
    padding: 3.5vw 0 0 0;
}
body > header a.a-h1 h1, body > header a.page-h1 span{
    text-decoration: none;
    font-family: 'Pathway Gothic One', 'Oswald', sans-serif;
    background-image: url(/assets/images/pametna-guja.png);
    background-size: 19vw auto;
    background-repeat: no-repeat;
    background-position: left 1.5vw top 1.5vw;
    padding: 1.75vw 0 0 0;
    font-size: 12.5vw;
    margin: 0;
    line-height: 100%;
    text-align: center;
    box-sizing: border-box;
    height: 24.5vw;
    display: block;
    font-weight: bold;
    /*box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.075);*/
}
header nav{
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 25vw;
    font-family: 'Saira Extra Condensed', 'Open Sans Condensed', sans-serif;
    font-size: 6.75vw;
    line-height: 130%;
    border-style: solid;
    border-width: 0 0 3vw 0;
    border-color: #157ac1;
}
header nav ul{
    background-color: #eaeaea;
    margin: 0;
    padding: 4vw 4vw 1vw 4vw;
    box-sizing: border-box;
    z-index: 100;
    border-style: solid;
    border-width: 0 0 0.25vw 0;
    border-color: #ffffff;
}
header nav ul li.non-link.cyr{
    font-size: 5vw;
}
header nav ul li.non-link{
    font-size: 5.5vw;
    padding: 0 0 1.5vw 0;
    display: block;
    line-height: 100%;
    border-style: solid;
    border-width: 0 0 0.25vw 0;
    border-color: #05988a;
    color: #a9a9a9;
}
header nav ul li.first{
    border: 0;
}
header nav ul li{
    display: block;
    margin: 0;
    border-style: solid;
    border-width: 0.25vw 0 0 0;
    border-color: white;
}
header nav ul li.separator{
    display: block;
    font-size: 6vw;
    border-style: solid;
    border-width: 0.25vw 0 0 0;
    border-color: #05988a;
    margin: 1vw 0 0 0;
    padding: 1vw 0 0 0;
    line-height: 145%;
}
header nav ul li.separator.first{
    margin: 2vw 0 0 0;
}
header nav ul li.separator a{
    padding: 0.5vw;
}
header nav ul li a.cyr{
    font-size: 5vw;
}
header nav ul li a{
    display: block;
    padding: 1vw 2.5vw 0.75vw 2.5vw;
    font-size: 6vw;
}

header .pametna-guja-logo{
    background-color: #eaeaea;
    display: block;
    text-align: center;
}
header .pametna-guja-logo img{
    width: 24vw;
    display: block;
    margin: 0 auto;
    padding: 5vw;
}

header .menu{
    list-style-type: none;
    margin: -7.5vw 3vw 0 0;
    padding: 1vw 0 1vw 0;
    font-size: 4vw;
    color: #009688;
    display: block;
    overflow: hidden;
    float: right;

}
header .menu li{
    float: left;
    margin: 0;
}
header .menu li:before{
    content: "|";
    padding: 2vw 1.5vw 0 1.5vw;
}
header .menu li:first-child:before{
    content: "";
}
header .menu li a{
    color: #009688;
    font-size: 3.625vw;
    padding: 2vw 0 2vw 0;
}
header .hamburger{
    position: absolute;
    display: block;
    width: 7vw;
    top: 3.75vw;
    right: 3.75vw;
    margin: 0;
    padding: 1.625vw;
}
header .hamburger img{
    width: 100%;
    display: block;
    padding: 0;
}
header aside{
    display: none;
    position: fixed;
    top: 40vw;
}
header aside p.language{
    margin: 10vw 0 0.25vw 2.5vw;
    padding: 0;
    font-size: 3.75vw;
}
header aside .languages{
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 4vw;
    color: #ffffff;
    width: 14vw;
}
header aside .languages li{
    margin: 0;
    width: 100%;
    padding: 0 0 1vw 0;
}
header aside .languages li a{
    color: #ffffff;
    font-size: 3.5vw;
    width: 100%;
    background-color: #d8e1e4;
    display: block;
    padding: 1.75vw 2vw 2vw 2vw;
    background-size: 7vw;
    background-repeat: no-repeat;
    background-position: right 2vw top 2vw;
}
header aside .languages li a.hr{
    background-image: url(/assets/images/hr-inactive.png);
}
header aside .languages li a.hr.active{
    background-image: url(/assets/images/hr.png);
}
header aside .languages li a.en{
    background-image: url(/assets/images/en-inactive.png);
}
header aside .languages li a.en.active{
    background-image: url(/assets/images/en.png);
}
header aside .languages li a.de{
    background-image: url(/assets/images/de-inactive.png);
}
header aside .languages li a.de.active{
    background-image: url(/assets/images/de.png);
}
header aside .languages li a.active{
    color: #ffffff;
    background-color: #009688;
}
header aside p.mask{
    margin: 8vw 0 0.25vw 2.5vw;
    padding: 0;
    font-size: 3.75vw;
}
header aside .masks{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
header aside .masks li{
    margin: 0;
    padding: 0 0 1vw 0;
}
header aside .masks li button{
    width: 18vw;
    display: block;
    height: 8vw;
    border-style: solid;
    border-width: 0;
}
header aside .masks li button.classic{
    background-color: #EBECEE;
    border-color: #CBCCCE;
}
header aside .masks li button.pink{
    background-color: #E794E8;
    border-color: #C774C8;
}
header aside .masks li button.blue{
    background-color: #61BCFB;
    border-color: #419CDB;
}
header aside .masks li button.active{
    border-width: 0 1vw 0 0;
}
.wrapper{
    clear: both;
    /*
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    */
}
.wrapper .container{
    overflow: hidden;
    padding:0 0 12vw 0;
    position: relative;
    /*
    width: 100%;
    top: 0;
    bottom: 0;
    position: absolute;
    padding: 0 0 20vw 0;
    overflow: hidden;
    */
}

.wrapper .container .content h1{
    font-family: 'Saira Extra Condensed', 'Open Sans Condensed', sans-serif;
    color: #009688;
}

.wrapper .container .start{
    display: block;
    width: 90vw;
    margin: 11vw 0 0 5vw;
    position: relative;
    overflow: hidden;
}

.wrapper .container .start a.close{
    display: none;
    position: absolute;
    top: 4vw;
    right: 2vw;
    box-sizing: border-box;
    text-align: center;
    width: 10vw;
    height: 10vw;
    padding: 2vw 0 0 0;
    background-color: #bd4646;
    border-radius: 50%;
    border: 1px solid #bb2222;
    box-shadow: inset 0 0.25rem 0.125rem 0 #ff5f5f, 0 -0.25rem 1.5rem rgba(187, 23, 23, 0.59) inset, 0 0.75rem 0.5rem #ff8e8e inset, 0 0.25rem 0.5rem 0 #a01515 inset;
    line-height: 100%;
}
.wrapper .container .start a.close img{
    width: 60%;
}

.wrapper .container .start p.arrow.cyr{
    font-size: 5vw;
}
.wrapper .container .start p.arrow{
    font-size: 5.25vw;
    margin: 0;
    padding: 0;
    font-family: 'Saira Extra Condensed', 'Open Sans Condensed', sans-serif;
    color: #009688;
    background-image: url(/assets/images/arrow-down.png);
    background-size: 18vw;
    background-position: 13vw 12vw;
    background-repeat: no-repeat;
    height: 28.5vw;
}
.wrapper .container .start p.arrow .title-1{
    width: 35vw;
    display: block;
    line-height: 120%;
    text-align: center;
}
.wrapper .container .start p.arrow.move-right{
    background-position: 34vw 10vw;
}
.wrapper .container .start p.arrow span.title-2{
    display: none;
    padding: 0 0 0 21vw;
}

.wrapper .container .start p.wait.en{
    font-size: 5.5vw;
    background-position: 20.5vw 1vw;
    padding: 7.25vw 0 0 31.25vw;
}
.wrapper .container .start p.wait{
    display: none;
    font-size: 5vw;
    font-family: 'Saira Extra Condensed', 'Open Sans Condensed', sans-serif;
    background-image: url(/assets/images/cloud-left.png);
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background-size: 58vw 26vw;
    background-position: 20.5vw 0vw;
    height: 44.25vw;
    padding: 6.25vw 0 0 28.25vw;
    margin: 0;
    width: 100%;
    color: #96989a;
    z-index: 100020;
}

.wrapper .container .start ul.basic-operations{
    list-style-type: none;
    margin: 1vw 0 0 0;
    padding: 0 0 0 43.5vw;
    height: 24vw;
}
.wrapper .container .start ul.basic-operations li{
}
.wrapper .container .start ul.basic-operations li a.name{
    display: none;
}
.wrapper .container .start ul.basic-operations li a.name.active{
    display: block;
    text-decoration: none;
}
.wrapper .container .start ul.basic-operations li a.name.cyr h3{
    font-size: 5.5vw;
    font-weight: bold;
}
.wrapper .container .start ul.basic-operations li a.name h3{
    font-family: 'Saira Extra Condensed', 'Open Sans Condensed', sans-serif;
    font-size: 6vw;
    font-weight: normal;
    color: #157ac1;
    margin: 0;
    padding: 3.5vw 4vw 0 0;
    text-align: center;
}
.wrapper .container .start ul.basic-operations li a.name.cyr h3:after{
    content: "СТАРТ";
}
.wrapper .container .start ul.basic-operations li a.name h3:after{
    content: "START";
    font-weight: normal;
    color: #ffffff;
    background-color: #157ac1;
    border: 0;
    font-size: 8vw;
    padding: 2.25vw 1vw 0 1vw;
    margin: -1vw 0 0 0;
    width: 42vw;
    border-radius: 0 3vw 3vw 0;
    display: block;
    text-align: center;
    float: left;
    line-height: 100%;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    height: 13vw;
    box-sizing: border-box;
}

.wrapper .container .start ul.basic-operations-logos{
    list-style-type: none;
    width: 24vw;
    margin: 0 0 0 20vw;
    padding: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url(/assets/images/logo.png);
    background-size: 24vw 24vw;
    background-position: 0 0;
    background-repeat: no-repeat;
}
.wrapper .container .start ul.basic-operations-logos li{
    width: 12vw;
    float: left;
    margin: 0;
    padding: 0;
}
.wrapper .container .start ul.basic-operations-logos li a{
    display: block;
}
.wrapper .container .start ul.basic-operations-logos li a span.button{
    display: block;
    width: 12vw;
    height: 12vw;
}
.wrapper .container .start ul.basic-operations-logos li a span.button img{
    width: 100%;
}



footer{
    width: 100vw;
    background-color: #009688;
    height: 58vh;
    position: relative;
}
footer .page-h1.cyr{
    font-size: 9vw;
    padding: 7vw 0 0 0;
}
footer .page-h1{
    color: #ecf3f9;
    font-family: 'Pathway Gothic One', 'Oswald', sans-serif;
    padding: 5vw 0 0 0;
    font-size: 10.5vw;
    margin: 0;
    line-height: 100%;
    text-align: center;
    box-sizing: border-box;
    display: block;
    font-weight: bold;
}
footer .page-h1:hover{
    text-decoration: none;
}
footer div.fb-like{
    display: block;
    margin: 0 auto;
    width: 20em;
    margin-top: 0.75em;
    color: #ffffff;
}
footer .share{
    display: block;
}
footer .social{
    color: #ffffff;
    text-align: center;
    margin-top: 3.5em;
    font-size: 0.95em;
}
footer .social p{
}
footer .social a{
    color: #ffffff;
    text-align: center;
    background-image: url(/assets/images/facebook-logo.png);
    background-size: 3.5em;
    background-position: center top;
    background-repeat: no-repeat;
    margin: 0 auto;
    padding: 3.75em 0 0 0;
    width: 15em;
    font-size: 0.9em;
    display: block;
}
footer .done-by{
    list-style-type: none;
    position: absolute;
    bottom: 1vw;
    right: 2vw;
    color: #e6eef0;
    font-size: 3.5vw;
    margin: 0;
}
footer .done-by li{
    float: left;
}
footer .done-by li:after{
    content: "|";
    margin: 0 0.5em 0 0.5em;
}
footer .done-by li:last-child:after{
    content: "";
    margin: 0;
}
footer .done-by li a{
    color: #e6eef0;
}


.bold{
    font-weight: bold;
}
.layer-bg{
    display: none;
    position: fixed;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    z-index: 99999;
}
#layer-bg-1{
    background-color: rgba(0, 0, 0, 0.775);
}
#layer-bg-2{
    background-color: rgba(0, 0, 0, 0.5);
}
#layer-bg-3{
    background-color: rgba(0, 0, 0, 0.225);
}
.layer-over-1{
    z-index: 100010;
    position: relative;
}
.layer-over-2{
    z-index: 100020;
    position: relative;
}
.layer-over-3{
    z-index: 100030;
    position: relative;
}


.bmo-banner{
    display: block;
    width: 70vw;
    margin: 0 auto;
    padding: 12vw 0 0 0;
    position: relative;
    overflow: hidden;
    font-size: 3vw;
    color: #157ac1;
}
.wrapper .container.addition .bmo-banner, .wrapper .container.subtraction .bmo-banner, .wrapper .container.multiplication .bmo-banner, .wrapper .container.division .bmo-banner{
    padding: 24vw 18vw 0 0;
}

.bmo-banner p.yt-title{
    padding: 0 0 0.25em 0;
    margin: 0;
}
.bmo-banner p.yt-title a{
    background-image: url(/assets/images/youtube-logo.png);
    background-size: 4.325em;
    background-position: 0 0.15em;
    background-repeat: no-repeat;
    padding: 0 0 0 4.65em;
    color: #157ac1;
}
.bmo-banner p.yt-channel{
    background: rgba(0, 150, 136, 0.04);
    padding: 4vw 5vw 4vw 5vw;
    margin: 0;
}
.bmo-banner p.yt-channel{
}
.bmo-banner p.yt-channel a{
    color: #157ac1;
}
.bmo-banner p.yt-channel a img{
    width: 100%;
    padding: 1.5vw 0 1vw 0;
}


.newsletter{
    padding: 2em 0 2.25em 0;
    color: #ffffff;
    font-size: 0.9em;
    background-color: rgba(130, 185, 185, 0.5);
}
footer .newsletter{
    box-shadow: 0 -10px 10px 0px rgba(0, 0, 0, 0.075);
    background-color: transparent;
}
.newsletter p{
    text-align: center;
    padding: 0.25em 0 0.5em 0;
    margin: 0;
    font-size: 0.95em;
}
.newsletter p input[type="email"]{
    padding: 0.35em;
    max-width: 16em;
    width: 80%;
    height: 1.5em;
    font-size: 1.05em;
}
html[lang="de"] .newsletter p input[type="email"]{
    max-width: 19em;
}
.newsletter p input[type="submit"]{
    width: 6em;
    height: 2.45em;
    text-align: center;
    font-size: 1.05em;
    line-height: 100%;
    color: #ffffff;
    background: #009688;
    border: 0;
}
footer .newsletter p input[type="submit"]{
    height: 2.35em;
    background: #efefef;
    color: #222222;
}
html[lang="en"] .newsletter p input[type="submit"]{
    width: 7em;
}
html[lang="de"] .newsletter p input[type="submit"]{
    width: 8em;
}
.newsletter p.iagree{
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
}
.newsletter p.iagree input[type="checkbox"]{
    width: 1.625em;
    height: 1.625em;
    float: left;
}
.newsletter p.iagree label{
    font-size: 0.85em;
    margin: 0.25em 0 0 0;
}
.newsletter p.iagree label a{
    color: #ffff88;
}


@media only screen and (min-width:30em){
    
    html{
        background-image: url(/assets/images/background-600.jpg);
        background-size: 37.5em;
        background-position: top 12em right -2.5em;
    }
    
    a:hover{text-decoration: underline; }

    body > header{
        margin: 0 auto;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;
        width: 100%;
    }
    body > header a.page-h1{
    }
    body > header a.a-h1, body > header a.page-h1{
        width: 100%;
        max-width: 40em;
        display: block;
        margin: 0 auto;
        text-decoration: none;
    }
    body > header a.a-h1.cyr h1, body > header a.page-h1.cyr span{
        font-size: 3.4em;
        padding: 0.375em 0 0 1.55em;
        background-size: 1.25em;
        height: 2.35em;
    }
    body > header a.a-h1 h1, body > header a.page-h1 span{
        font-size: 4em;
        background-size: 1.0625em;
        background-position: left 0.125em top 0.1875em;
        text-align: left;
        padding: 0.1875em 0 0 1.325em;
        height: 2em;
        width: 100%;
        max-width: 40em;
        display: block;
    }
    header .hamburger{
        width: 2.5em;
        top: 0.8em;
        right: 1em;
        padding: 1em;
    }
    
    header nav{
        top: 8.125em;
        font-size: 1em;
        border-width: 0 0 0.125em 0;
        max-width: 40em;
        margin: 0 auto;
    }
    header nav ul{
        padding: 1em 1em 0 1em;
        border-width: 0 0 0.0625em 0;
    }
    header nav ul li.non-link.cyr{
        font-size: 1.5em;
    }
    header nav ul li.non-link{
        font-size: 1.625em;
        padding: 0 0 0.25em 0;
        border-width: 0 0 0.0625em 0;
    }
    header nav ul li{
        border-width: 0.0625em 0 0 0;
    }
    header nav ul li.separator{
        font-size: 1em;
        border-width: 0.0625em 0 0 0;
        margin: 0em 0 0 0;
        padding: 0.25em 0 0.2em 0;
    }
    header nav ul li.separator.first{
        margin: 1em 0 0 0;
    }
    header nav ul li.separator a{
        padding: 0.5em;
    }
    header nav ul li a.cyr{
        font-size: 1.5em;
    }
    header nav ul li a{
        padding: 0.625em 0.5em 0.5em 1em;
        font-size: 1.625em;
    }
    
    
    header .pametna-guja-logo img{
        width: 8em;
        padding: 1em;
    }
    
    
    header .menu{
        /*margin: -2.75em 2em 0 0;*/
        padding: 1em 0 1em 0;
        font-size: 1em;
        margin: 0 auto;
        display: block;
        float: none;
        text-align: center;
        margin-top: -2.75em;
        width: 24em;
        padding-left: 8em;
        padding-bottom: 0.5em;
    }
    
    header .menu li{
        margin: 0;
    }
    header .menu li:before{
        content: "|";
        padding: 2em 0.75em 0 0.75em;
    }
    header .menu li a{
        font-size: 1em;
    }
    
    .wrapper .container{
        padding:0 0 5em 0;
    }
    .wrapper .container .start{
        position: relative;
        width: 100%;
        max-width: 40em;
        margin: 0 auto;
        margin-top: 4em;
        padding: 0 2em 0 2em;
        box-sizing: border-box;
    }
    .wrapper .container .start p.wait.en{
        font-size: 1.5em;
        background-position: 8.25em 0;
        padding: 2em 0 0 11.0625em;
    }
    .wrapper .container .start p.wait{
        font-size: 1.5em;
        background-size: 12em 7em;
        background-position: 8.25em 0;
        height: 5em;
        padding: 2em 0 0 10.125em;
    }
    .wrapper .container .start p.arrow.cyr{
        font-size: 1.35em;
    }
    .wrapper .container .start p.arrow{
        font-size: 1.5em;
        background-size: 4em;
        background-position: 4em 3em;
        height: 7em;
    }
    .wrapper .container .start p.arrow .title-1{
        width: 8em;
    }
    
    
    .wrapper .container .start ul.basic-operations{
        margin: 1em 0 0 0;
        padding: 0 0 0 16em;
        height: 5em;
        width: 11.5em;
    }
    .wrapper .container .start ul.basic-operations li a.name.cyr h3{
        font-size: 1.35em;
        font-weight: normal;
    }
    .wrapper .container .start ul.basic-operations li a.name h3{
        font-size: 1.4em;
        margin: 0;
        padding: 0em 1em 0 0;
    }
    .wrapper .container .start ul.basic-operations li a:hover{
        color: #157ac1;
    }
    .wrapper .container .start ul.basic-operations li a.name h3:after{
        font-size: 1.25em;
        padding: 0.4em 1em 0.25em 1em;
        margin: -0.0625em 0 0 0;
        width: 5.75em;
        border-radius: 0 0.4em 0.4em 0;
        height: 1.8em;
    }

    .wrapper .container .start ul.basic-operations-logos{
        width: 6em;
        margin: 0 0 0 12em;
        background-size: 6em 6em;
    }
    .wrapper .container .start ul.basic-operations-logos li{
        width: 3em;
    }
    .wrapper .container .start ul.basic-operations-logos li a span.button{
        width: 3em;
        height: 3em;
    }
    
    
    footer{
        width: 100%;
        height: 31em;
    }
    footer .page-h1.cyr{
        font-size: 2.25em;
        padding: 0.75em 0 0 0;
    }
    footer .page-h1{
        padding: 0.5em 0 0 0;
        font-size: 2.75em;
    }
    footer .done-by{
        bottom: 0.75em;
        right: 1.1em;
        font-size: 0.9em;
    }
    
    
    
    
    .bmo-banner{
        width: 75%;
        padding: 4em 0 0 0;
        font-size: 1em;
    }
    .wrapper .container.addition .bmo-banner, .wrapper .container.subtraction .bmo-banner, .wrapper .container.multiplication .bmo-banner, .wrapper .container.division .bmo-banner{
        padding: 11em 6em 0 0;
    }
    .bmo-banner p.yt-channel{
        padding: 1.5em 2em 1.5em 2em;
    }
    .bmo-banner p.yt-channel a{
    }
    .bmo-banner p.yt-channel a img{
        padding: 0.75em 0 0.75em 0;
    }
    
}


@media only screen and (min-width:42em){
    .bmo-banner{
        width: 25em;
    }
}


div.share div.fb-like.fb_iframe_widget span iframe{height: 70px !important; }
div.share.in_article div.fb-like.fb_iframe_widget span{height: 30px !important; }
div.share.in_article div.fb-like.fb_iframe_widget span iframe{height: initial !important; }
div.share.in_footer div.fb-like.fb_iframe_widget span iframe{height: 30px !important; }
